<style>
.page-title-card {
  display: flex;
  width: 100%;
}

.page-title-card-body {
  padding: 0.7rem;
  background: #2a3042;
  border-top: 1px solid #222736;
  border-left: 1px solid #222736;
  flex-grow: 1;
  box-shadow: 0 1px 1px rgba(0,0,0,.75);
}

.c-container {
  display: grid;
  grid-area: content;
  grid-template-columns: 5fr auto;
  grid-column-gap: 20px;
  margin-left: 1rem;
  margin-right: 1rem;
}

.c-special-text {
  text-decoration-style: dotted;
  text-decoration-line: underline;
  text-decoration-color: white;
  cursor: pointer;
}
.c-underline-warning {
  text-decoration-color: var(--warning);
}
@media (max-width: 768px) {
  .c-container {
    grid-column-gap: 15px;
    margin-left: unset;
    margin-right: unset;
  }
}
.c-container-left,
.c-container-right {
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  grid-column-gap: 20px;
}
.c-container-left {
  justify-content: start;
}
.c-container-right {
  justify-content: end;
}
.c-container-item {
//display: grid;
  align-items: center;
  grid-gap: 6px;
//grid-auto-flow: column;
  cursor: default;
}
@media (min-width: 1025px) {
  .c-container-mobile-alt {
    display: none !important;
  }
  .c-mobile {
    display: none !important;
  }
}
@media (max-width: 1024px) {
  .c-desktop {
    display: none !important;
  }

  .c-container-hide-sm {
    display: none !important;
  }

  .c-container-data {
    display: block;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
<script>

import CopyableText from "@/components/CopyableText";
import {with_acrf_token} from "@/methods";
import {mapGetters} from "vuex";
import TimeAgo from "vue2-timeago";
import {WorkerState} from "@/enums";

export default {
  props: ['server_id'],
  computed: {
    ...mapGetters({
      getServer: 'getServer',
      getUILanguage: 'getUILanguage',
      getDTLocale: 'getDTLocale'
    }),
    uptime() {
      if(!this.server.status.uptime) return new Date();
      return new Date(Date.now() - 1000 * this.server.status.uptime);
    }
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    CopyableText,
    TimeAgo,
    Frame: () => import('@/components/lazy/servers/dashboard/Frame')
  },
  methods: {
    identifierLoaded(identifier, canModify) {
      this.identifier = identifier;
      if(typeof(canModify) !== 'undefined') this.identifierModifiable = canModify;
    },
    async updateIdentifier() {
      if(!this.identifierModifiable) return;
      let input = await this.$swal({
        title: this.$t('server.view.identifier.prompt'),
        input: 'text',
        inputPlaceholder: this.$t('server.view.identifier.placeholder'),
        showCloseButton: true,
      });
      if(input.isConfirmed) {
        let ref = this;
        with_acrf_token().then((r) => {
          let payload = {
            acsrf_token: r.acsrf_token,
            identifier: input.value
          };
          fetch(process.env.VUE_APP_ROOT_API + `v1/server/${ref.server_id}/identifier`, {
            method: 'POST',
            body: JSON.stringify(payload),
            credentials: 'include'
          })
            .then(response => {
              if(response.ok || response.status === 400){
                return response.json();
              } else {
                if(response.status === 429) {
                  this.$swal({
                    icon: 'warning',
                    text: this.$t('error.server.ratelimit.message'),
                    title: this.$t('error.server.ratelimit.title')
                  });
                } else
                  throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
              }
            })
            .then(data => {
              if(!data) return;
              if(data.status) {
                this.identifierLoaded(data.server.identifier);
                this.$swal({
                  icon: 'success',
                  text: this.$t('server.view.identifier.update.success')
                });
              } else {
                this.$swal({
                  icon: 'error',
                  text: this.$t('server.view.identifier.update.errors.violation')
                });
              }
            })
            .catch(error => {
              console.log(`[ERROR] ${error}`);
              this.$swal({
                icon: 'error',
                text: this.$t('server.view.identifier.update.errors.generic')
              });
            });
        }).catch(error => {
          console.log('[ERROR] Failed to request acrf token');
          this.$swal({
            icon: 'error',
            text: this.$t('error.server.generic.message')
          });
        });
      }
    },
    triggerServerUpdate(data) {
      let server_id = data.server_id || data.server.id;
      if(server_id === this.server_id) {
        if(process.env.NODE_ENV === 'development') console.log('[Dashboard] Server update acknowledged');
        this.$nextTick(() => {
          let wasServerOffline = (this.server.state === WorkerState.ERROR);
          this.server = null;
          this.server = this.getServer(this.server_id);
          this.$nextTick(() => {
            if(this.$refs.frame && this.$refs.frame.$refs.component) {
              this.$refs.frame.$refs.component.updateConnectionStatus(this.server.state === WorkerState.CONNECTED ? true : false);
            }
          });

          if(this.server.state === WorkerState.CONNECTED && wasServerOffline) {
            // Wait 10 seconds and then refresh features to re-enable map
            setTimeout(() => {
              this.getFeatures();
              this.$refs.frame.$forceUpdate();
            }, 1000 * 10);
          }
        });
      }
    },
    async getFeatures() {
      try {
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/dashboard-features`, {
          method: 'GET',
          credentials: 'include'
        });
        if(response.ok) {
          let data = await response.json();
          this.permissions = data.permissions;
          this.features = data.features;
          this.console = data.console;
          this.server_data = data.server;
        } else {
          if(response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.short'));
          } else if(response.status === 403) {
            await this.$swal({
              icon: 'error',
              title: 'Forbidden'
            });
            await this.$router.push({name: 'dashboard'});
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$toast.error(this.$t('error.server.generic.message'));
      }
    },
    async fetchMetrics() {
      try {
        let url = new URL(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/metrics`);
        let response = await fetch(url, {
          method: 'GET',
          credentials: 'include'
        });
        if(response.ok) {
          let data = await response.json();
          this.serverFps = data.server_fps.reverse();
          this.currentServerFps = this.serverFps[this.serverFps.length-1];

					if(data.entity_count) {
						this.entityCount = data.entity_count.reverse();
						this.currentEntityCount = this.entityCount[this.entityCount.length - 1];
					}
        } else {
          throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
      }
    },
    async handleServerMetrics(event) {
      let server_id = event.server_id || event.server.id;
      if(server_id !== this.server_id) return;
      this.currentServerFps = event.metrics.serverFps;
      this.serverFps.push(this.currentServerFps);
      this.serverFps = this.serverFps.slice(-10);

			if(event.metrics.entityCount) this.currentEntityCount = event.metrics.entityCount;

      try {
        this.$refs.fpsChart.updateSeries([{data: this.serverFps}]);
        this.$refs.fpsChartMobile.updateSeries([{data: this.serverFps}]);
        // eslint-disable-next-line no-empty
      } catch(e) {}
    }
  },
  async created() {
    this.server = this.getServer(this.server_id);
    this.$socket.client.on('server:update', this.triggerServerUpdate);
    this.$socket.client.on('server:state', this.triggerServerUpdate);
    this.$socket.client.on('server:metrics', this.handleServerMetrics);
    this.$socket.client.on('gsm:player:create', this.triggerServerUpdate); // player join
    this.$socket.client.on('gsm:player:destruct', this.triggerServerUpdate); // player leave
    await this.getFeatures();
    this.ready = true;
  },
  async mounted() {
    await this.fetchMetrics();
    try {
	    this.$refs.fpsChart.updateSeries([{data: this.serverFps}]);
	    this.$refs.fpsChartMobile.updateSeries([{data: this.serverFps}]);
      // eslint-disable-next-line no-empty
    } catch(e) {}
  },
  async destroyed() {
    this.$socket.client.off('server:update', this.triggerServerUpdate);
    this.$socket.client.off('server:state', this.triggerServerUpdate);
    this.$socket.client.off('server:metrics', this.handleServerMetrics);
    this.$socket.client.off('gsm:player:create', this.triggerServerUpdate); // player join
    this.$socket.client.off('gsm:player:destruct', this.triggerServerUpdate); // player leave
  },
  data() {
    return {
      WorkerState: WorkerState,
      ready: false,
      currentTab: null,
      options: {},
      identifier: this.server_id,
      identifierModifiable: false,
      server: {},

      permissions: {},
      features: {},
      console: {},
      server_data: {},

      serverFps: [],
      currentServerFps: 0,

	    entityCount: [],
	    currentEntityCount: null,

      serverfpsChart: {
        series: [
					{
            name: 'FPS',
            data: []
          }
				],
        chartOptions: {
          chart: {
            type: 'area',
            height: 50,
            sparkline: {
              enabled: true
            }
          },
          stroke: {
            curve: 'smooth',
            width: 2,
          },
          colors: ['#4285F4'],
          animations: {
            enabled: true,
            easing: 'linear',
            dynamicAnimation: {
              speed: 1000
            }
          },
          fill: {
            type: 'gradient',
            gradient: {
              shadeIntensity: 1,
              inverseColors: false,
              opacityFrom: 0.45,
              opacityTo: 0.05,
              stops: [25, 100, 100, 100]
            },
          },
          tooltip: {
            fixed: {
              enabled: false
            },
            x: {
              show: false
            },
            marker: {
              show: false
            }
          }
        }
      }
    }
  }
};
</script>

<template>
  <div class="loose-page-content">
    <div class="page-title-card">
      <div class="page-title-card-body">
        <div class="c-container">
          <!-- Core info -->
          <div class="c-container-left">
            <div class="c-container-item c-container-data mt-n1">
              <span ref="identifier"
                    id="identifier"
                    class="h3 pb-0 mb-0"
                    :class="{'c-special-text': identifierModifiable, 'c-underline-warning': (this.identifier === this.server_id)}"
                    v-on:click="updateIdentifier()">
                {{ identifier }}
              </span>
              <b-tooltip target="identifier" v-if="identifierModifiable">
                {{ $t('server.view.identifier.tooltip') }}
              </b-tooltip>
            </div>

            <!-- Mobile hidden -->
            <!--
            <div class="c-container-item c-container-data c-container-hide-sm">
              <span class="h6 pb-0 mb-0">
                Cloud ID
              </span>
              <CopyableText class="text-code" :text="server_id"/>
            </div>
            -->
            <template v-if="server.online === false && (server.state === WorkerState.ERROR || server.state === WorkerState.DISCONNECTED)">
              <span class="font-size-16 badge badge-danger">
                <i class="far fa-exclamation-triangle" />
                {{ $t('server.view.offline') }}
              </span>
            </template>
            <template v-else-if="server.warning && (server.state === WorkerState.ERROR || server.state === WorkerState.DISCONNECTED)">
              <span class="font-size-16 badge badge-warning">
                <i class="far fa-exclamation-triangle" />
                {{ $t('server.view.warning') }}
              </span>
            </template>
            <template v-else-if="server.error && server.state === WorkerState.ERROR">
              <span class="font-size-16 badge badge-danger">
                <i class="far fa-exclamation-triangle" />
                {{ $t('server.view.error') }}
              </span>
            </template>
            <template v-else>
              <!-- Players -->
              <div class="c-container-item c-container-data c-container-hide-sm">
                <span class="h6 pb-0 mb-0 mr-n3">
                  Players
                </span>
              </div>
              <div class="c-container-item c-container-data c-container-hide-sm">
                <span class="h4 pb-0 mb-0 ml-1">
                  {{ server.status.players }} / {{ server.status.slots }}
                  <template v-if="server.status.queue.active">
                    + {{ server.status.queue.size }}
                  </template>
                </span>
              </div>

              <!-- Uptime -->
              <template v-if="server.status.uptime">
                <div class="c-container-item c-container-data c-container-hide-sm">
                  <span class="h6 pb-0 mb-0">
                    Up time

                  </span>
                </div>
                <div class="c-container-item c-container-data c-container-hide-sm">
                  <span class="h4 pb-0 mb-0 ml-n3">
                    <time-ago :refresh="60" :datetime="uptime" :locale="getUILanguage()"></time-ago>
                  </span>
                </div>
              </template>

              <!-- Next restart -->
              <template v-if="server.status.next_restart">
                <div class="c-container-item c-container-data c-container-hide-sm">
                  <span class="h6 pb-0 mb-0">
                    Next restart
                  </span>
                </div>
                <div class="c-container-item c-container-data c-container-hide-sm">
                  <span class="h4 pb-0 mb-0 ml-n3">
                    {{ $d(parseDate(server.status.next_restart), 'datetime', getDTLocale()) }}
                  </span>
                </div>
              </template>

              <template v-if="currentServerFps">
                <div class="c-container-item c-container-data c-container-hide-sm">
                  <div class="h6 pb-0 mb-0 ml-2" :class="{'text-danger': currentServerFps < 30, 'text-warning': currentServerFps < 100 && currentServerFps >= 30}">
                    {{ $t('server.dashboard.environment.serverfps') }}: {{ currentServerFps }}
                  </div>
	                <template v-if="currentEntityCount">
		                <div class="h6 pb-0 mb-0 ml-2" style="font-size: 0.75rem;">
			                {{ $t('server.dashboard.environment.entitycount') }}: {{ currentEntityCount }}
		                </div>
	                </template>
                </div>
                <div class="c-container-item c-container-data c-container-hide-sm">
                  <apexchart
                      ref="fpsChart"
                      class="apex-charts"
                      height="40"
                      type="area"
                      dir="ltr"
                      :series="serverfpsChart.series"
                      :options="serverfpsChart.chartOptions"
                  ></apexchart>
                </div>
              </template>
              <template v-if="server.status.environment">
                <template v-if="server.status.environment.gametime">
                  <div class="c-container-item c-container-data c-container-hide-sm">
                    <span class="h6 pb-0 mb-0 ml-2">
                      {{ $t('server.dashboard.environment.gametime') }}
                    </span>
                  </div>
                  <div class="c-container-item c-container-data c-container-hide-sm">
                  <span class="h4 pb-0 mb-0 ml-n3">
                    {{ server.status.environment.gametime }}
                  </span>
                  </div>
                </template>
              </template>

            </template>
          </div>
          <div class="c-container-right">
            <button class="btn btn-outline-dark" :disabled="server.state !== WorkerState.CONNECTED" v-if="features['dynamic-actions'] && features.gamelabs.poll_protocol >= 2" v-on:click="$refs.frame.$refs.component.worldContextMenuOpen()">
              <i class="fal fa-code-branch" />
              <span class="hide-sm">
                Actions
              </span>
            </button>
            <button class="btn btn-outline-dark" :disabled="server.state !== WorkerState.CONNECTED" v-if="permissions.console" v-on:click="$refs.frame.$refs.component.toggleConsole()">
              <i class="fal fa-terminal" />
              <span class="hide-sm">
                {{ $t('server.dashboard.console.button') }}
              </span>
            </button>
            <button class="btn btn-outline-dark" :disabled="server.state !== WorkerState.CONNECTED" v-on:click="$refs.frame.$refs.component.toggleServerPanel()">
              <i class="fal fa-server" />
              <span class="hide-sm">
                {{ $t('server.dashboard.server.button') }}
              </span>
            </button>
          </div>
          <!-- Infoset for mobile -->
          <div class="c-container-mobile-alt">
            <!--
            <div class="mobile-c-container-item">
              <span class="h6 pb-0 mb-0">
                Server ID
              </span>
              <CopyableText class="text-code" :text="server_id"/>
            </div>
            -->
            <template v-if="currentServerFps">
              <div class="mobile-c-container-item">
                <apexchart
                    ref="fpsChartMobile"
                    class="apex-charts"
                    height="40"
                    type="area"
                    dir="ltr"
                    :series="serverfpsChart.series"
                    :options="serverfpsChart.chartOptions"
                ></apexchart>
              </div>
            </template>
          </div>
          <div class="c-container-mobile-alt" v-if="currentServerFps">
            <div class="c-container mt-2">
              <div class="mobile-c-container-item">
                <div class="h6 pb-0 mb-0" :class="{'text-danger': currentServerFps < 30, 'text-warning': currentServerFps < 100 && currentServerFps >= 30}">
                  FPS: {{ currentServerFps }}
                </div>
	              <template v-if="currentEntityCount">
		              <div class="h6 pb-0 mb-0" style="font-size: 0.75rem;">
			              {{ $t('server.dashboard.environment.entitycount') }}: {{ currentEntityCount }}
		              </div>
	              </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Frame ref="frame" v-if="ready" @identifierLoaded="identifierLoaded" :worker="server" :server="server_data" :console="console" :features="features" :permissions="permissions" :server_id="server_id"/>
  </div>
</template>
